<template>
  <div>
    <!-- kyc page for Evolet user -->
    <div class="page-body">
      <loading
        :active="loader"
        :is-full-page="true"
        :opacity="0.9"
        color="#ff6b00"
      />
      <TitleBar :title="$t('Balance')" />
      <v-container>
        <v-card flat class="border mt-6" rounded="lg">
          <v-card
            class="pa-2 main-card v-card-tile h-100"
            rounded="lg"
            elevation="0"
          >
            <v-row no-gutters>
              <label class="pt-1 font-weight-bold ps-1 m-0 text-color">{{
                $t("Balance History")
              }}</label>
            </v-row>
            <v-row class="d-flex justify-space-between ma-2">
              <v-col cols="12" md="4">
                <v-card class="pa-2 border" elevation="0">
                  <div
                    class="font-weight-bold text-left ms-3 mt-2 text-color font-size"
                  >
                    {{ $t("Balance") }}
                  </div>
                  <div
                    class="text-right primary--text text-h5 font-weight-bold"
                  >
                    <span link>$ {{ balance.available_balance }}</span>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card class="pa-2 border" elevation="0">
                  <div
                    class="font-weight-bold text-left ms-3 mt-2 text-color font-size"
                  >
                    {{ $t("Incoming") }}
                  </div>
                  <div
                    class="text-right primary--text text-h5 font-weight-bold"
                  >
                    <span link>$ {{ balance.incoming_balance }}</span>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card class="pa-2 border" elevation="0">
                  <div
                    class="font-weight-bold text-left ms-3 mt-2 text-color font-size"
                  >
                    {{ $t("Outing") }}
                  </div>
                  <div
                    class="text-right primary--text text-h5 font-weight-bold"
                  >
                    <span link>$ {{ balance.outgoing_balance }}</span>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
          <!-- <v-card-title>
            <v-row class="mt-2">
              <v-col>
                <v-text-field
                  v-model="search"
                  type="date"
                  outlined
                  dense
                  label="From Date"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="search"
                  label="To Date"
                  type="date"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="search"
                  type="date"
                  outlined
                  dense
                  label="From Date"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title> -->

          <v-dialog v-model="paymentdialog" max-width="700px">
            <v-card class="rounded-lg">
              <div class="pt-3">
                <p class="text-center slot-icon">
                  <i class="fa fa-circle-dollar-to-slot fw-slot-icon"></i>
                </p>
                <p class="text-center font-weight-bold">
                  Create Payment Requiest
                </p>
              </div>
              <v-card-text>
                <v-form v-model="valid" ref="busApplicant">
                  <v-col>
                    <label class="mt-0"
                      >Amount<span style="color: #f00">*</span></label
                    >
                    <v-text-field
                      :placeholder="$t('please_enter_amount')"
                      outlined
                      type="number"
                      hide-spin-buttons
                      required
                      dense
                      ><template v-slot:prepend-inner class="pt-0">
                        <span class="font-weight-bold mt-1 me-2">$</span>
                      </template></v-text-field
                    >
                  </v-col>
                  <v-col>
                    <v-checkbox
                      class="mb-0 text-checkbox pb-0"
                      hide-details
                      :label="$t('include_gst')"
                    ></v-checkbox>
                  </v-col>
                  <v-col>
                    <label>Expires At<span style="color: #f00">*</span></label>
                    <v-text-field
                      :placeholder="$t('please_enter_amount')"
                      outlined
                      type="date"
                      hide-spin-buttons
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                </v-form>
              </v-card-text>
              <v-divider class="p-o m-0"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="paymentdialog = false" outlined>
                  {{ $t("cancel_label") }}
                </v-btn>
                <v-btn
                  @click="opensuceesDialog()"
                  class="elevation-0"
                  color="primary"
                  >{{ $t("create_payment_link") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="successdialog" max-width="500px">
            <v-card class="rounded-lg">
              <p class="text-end mb-0">
                <v-btn
                  fab
                  icon
                  text
                  small
                  class="elevation-1 me-2 mt-2"
                  color="primary"
                  @click="successdialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </p>

              <v-card-text>
                <div class="mb-4">
                  <p class="text-center check-icon-success mb-3">
                    <i class="fa fa-check fw-check-success"></i>
                  </p>
                  <p class="text-center font-weight-bold">
                    Your Payment is Created Succesfully.
                  </p>
                  <p class="ms-6 mb-0">
                    Please copy the generated <b>PAY ID</b> for proceeding your
                    payment.
                  </p>
                  <div class="d-flex justify-content-center align-item-center">
                    <v-text-field
                      v-model="resultPayid"
                      disabled
                      filled
                      class="ps-6 text-center"
                      hide-details
                    ></v-text-field>
                    <v-btn
                      @click="copyPayId()"
                      icon
                      fab
                      small
                      class="ms-2 me-4 mt-2"
                    >
                      <v-icon class="pa-2">mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                </div></v-card-text
              >
            </v-card>
          </v-dialog>
          <v-row class="ma-3">
            <v-btn
              depressed
              class="ms-3 mt-3"
              @click="downloadExcelsheet"
              color="primary"
              small
              ><v-icon small>mdi-file-excel-outline</v-icon>Export to Excel
            </v-btn>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field
                placeholder="Search By TXN Pin/Status/Payment Mode"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search By TXN Pin/Status/Payment Mode"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :search="search"
            :items="BalanceHistory"
            class="elevation-0"
          >
            <template v-slot:item.status="{ item }">
              <v-chip
                v-if="item.status"
                small
                class="text-capitalize"
                :color="getColor(item.status)"
                dark
              >
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:item.amount="{ item }">
              <span class="font-weight-bold" :class="returnTextCol(item)">
                {{ returntxnType(item) }} {{ item.amount }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import TitleBar from "@/components/navigations/TitleBar.vue";
import alerts from "@/mixins/alerts";
import transactionService from "@/services/TransactionService/transactionService.js";
export default {
  mixins: [alerts],
  data() {
    return {
      resultPayid: "WFG43526RTRT",
      paymentdialog: false,
      successdialog: false,
      valid: true,
      loader: false,
      search: "",
      balance: {},
      headers: [
        {
          text: "Transaction Pin",
          align: "start",
          sortable: false,
          value: "transaction_pin",
        },
        { text: "Status", value: "status" },
        { text: "Payment Mode", value: "payment_mode" },
        { text: "Date", value: "created_date" },
        { text: "Amount($)", value: "amount" },
      ],
      BalanceHistory: [],
    };
  },
  components: {
    TitleBar,
    Loading,
    // EvoletKyc,
  },
  mounted() {
    this.getBalance();
    this.getBalanceHistory();
  },
  methods: {
    async downloadExcelsheet() {
      if (this.BalanceHistory) {
        const tempArray = Array.from(this.BalanceHistory);
        const temp = tempArray.map((ele) => {
          return {
            transaction_pin: ele.transaction_pin,
            status: ele.status,
            payment_mode: ele.payment_mode,
            created_date: ele.created_date,
            amount: ele.txn_type == "I" ? `+ ${ele.amount}` : `- ${ele.amount}`,
          };
        });
        this.exportToExcel(this.headers, temp, "Balance History");
      }
    },
    returntxnType(item) {
      if (item.txn_type === "I") {
        return "+";
      } else if (item.txn_type == "O") {
        return "-";
      }
    },
    returnTextCol(item) {
      if (item.txn_type === "I") {
        return "success--text";
      } else if (item.txn_type == "O") {
        return "red--text";
      }
    },
    openDialog() {
      this.paymentdialog = true;
    },
    opensuceesDialog() {
      this.paymentdialog = false;
      this.successdialog = true;
    },
    copyPayId() {
      navigator.clipboard.writeText(this.resultPayid);
      this.showAlertSuccess("Pay id copied successfully.");
    },
    async getBalance() {
      this.loader = true;
      try {
        const response = await transactionService.getBalance();
        if (response.data && response.data.status_code === 200) {
          this.loader = false;
          console.log("response.data.data[0]", response.data.data[0]);
          this.balance = response.data.data[0];
        }
      } catch (error) {
        this.loader = false;
        this.balance = {};
        console.log(error);
      }
    },
    async getBalanceHistory() {
      this.loader = true;
      try {
        const response = await transactionService.getBalanceHistory({
          skip: 0,
          limit: 300000,
        });
        if (response.data && response.data.status_code === 200) {
          this.loader = false;
          console.log("response.data.data[0]", response.data.data[0]);
          this.BalanceHistory = response.data.data;
        }
      } catch (error) {
        this.loader = false;
        this.balance = {};
        console.log(error);
      }
    },
    getColor(status) {
      if (status == "received") return "green";
      else if (status == "pending") return "orange";
      else if (status == "created") return "yellow darken-2";
      else if (status == "failed") return "red";
      else return "blue";
    },
  },
};
</script>
<style lang="scss" scoped>
.v-input--selection-controls {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.slot-icon {
  padding-top: 3px;
  font-size: 40px;
  border: 10px solid #ffe7d6;
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background-color: #fdd1b2;
  margin: auto;
}

.fw-slot-icon {
  color: white;
}
.check-icon-success {
  padding-top: 12px;
  font-size: 40px;
  font-weight: 800;
  border: 12px solid rgb(215, 255, 215);
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background-color: rgb(71, 182, 71);
  margin: auto;
}

.fw-check-success {
  color: white;
}
.v-input--checkbox::v-deep {
  .v-label,
  .v-icon {
    margin-bottom: 0px !important;
  }
}
</style>
